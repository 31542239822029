




































































import { formatDate } from '@/utils/helpers'
import { defineComponent, reactive } from '@vue/composition-api'
import type { Header } from '@/components/composables/useDataTable/types'
import { useDataTable } from '@/components/composables/useDataTable'
import MyTenderService from '@/services/myTender'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import TendersSearchFilters from '@/views/Tender/partials/TendersSearchFilters.vue'
import EditButton from '@/components/buttons/EditButton.vue'
import { ProcurementStatus } from '@/services/myTender/types'
import ShowButton from '@/components/buttons/ShowButton.vue'

export default defineComponent({
  setup() {
    const searchParams = reactive({ filters: { startDate: undefined, endDate: undefined, status: undefined } })
    const { items, totalRecords, loading, pagination, search, query, pages, fetchData } = useDataTable(
      MyTenderService,
      searchParams,
    )

    return {
      items,
      totalRecords,
      loading,
      pagination,
      search,
      query,
      pages,
      fetchData,
      formatDate,
      MyTenderService,
      searchParams,
      ProcurementStatus,
    }
  },
  name: 'MyTendersList',
  components: { ShowButton, TendersSearchFilters, TenderStatus, EditButton },
  computed: {
    headers(): Array<Header> {
      return [
        { value: 'capacity', text: this.$t('capacity'), sortable: true },
        { value: 'name', text: this.$t('description'), sortable: true },
        { value: 'isPublishedAt', text: this.$t('publishDate'), sortable: true },
        { value: 'endsAt', text: this.$t('closureDate'), sortable: true },
        { value: 'transactionStartsAt', text: this.$t('startDate'), sortable: true },
        { value: 'transactionEndsAt', text: this.$t('endDate'), sortable: true },
        { value: 'status', text: this.$t('status'), sortable: true },
        { value: 'actions', text: this.$t('actions'), sortable: false },
      ]
    },
  },
})
