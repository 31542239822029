import api from '@/plugins/api'
import type { GetMyTendersResponse, FindMyTenderResponse, UpdateMyTenderResponse } from './types'

const MyTenderService = {
  all(query = ''): Promise<GetMyTendersResponse> {
    return api().get(`/me/procurements${query}`)
  },

  find(id: string | number): Promise<FindMyTenderResponse> {
    return api().get(`/me/procurements/${id}`)
  },

  update(
    procurementId: string | number,
    offerId: string | number,
    payload: Record<string, unknown>,
  ): Promise<UpdateMyTenderResponse> {
    return api().put(`/me/procurements/${procurementId}/offers/${offerId}`, payload)
  },
}

export default MyTenderService
